<template>
  <v-container fluid class="py-0">
    <heading />
    <payments-table>
      <template #[`empty-search`]> &nbsp; </template>
    </payments-table>
  </v-container>
</template>

<script>
import Heading from "./components/Heading";
import PaymentsTable from "./Bookings/Table";

export default {
  components: {
    Heading,
    PaymentsTable,
  },
  props: {},
  mixins: [],
  computed: {},
  data: () => ({}),
  async created() {},
  watch: {},
  methods: {},
};
</script>

<style scoped lang="scss">
.container {
  .big-chips {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    > div {
      flex: 1;
      min-width: 300px;
    }
  }
}
</style>
